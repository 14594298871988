import classNames from "classnames";
import React from "react";

const ScenarioFilterButton = ({
  isDisabled = false,
  isSelected = false,
  buttonText,
  onClick = () => {},
  icon,
}) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      tabIndex="0"
      type="button"
      className={classNames("scenario-filter-item", {
        selected: isSelected,
      })}
    >
      {buttonText}
      {!!icon && <div className="plus-icon">{icon}</div>}
    </button>
  );
};

ScenarioFilterButton.propTypes = {};

export default ScenarioFilterButton;
